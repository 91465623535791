import TagManager from 'react-gtm-module';
import { ScrollRestoration } from 'react-router-dom';
import customGetKey from '../../utils/routes/customGetKey';
import Pages from './pages';
import { useEffect } from 'react';

function App() {
  useEffect(() => TagManager.initialize({ gtmId: import.meta.env.VITE_GTM_ID }), []);

  return (
    <>
      <ScrollRestoration getKey={customGetKey} />
      <Pages />
    </>
  );
}

export default App;

// triggering build
